.projects-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
}

@media (max-width: 768px) {

  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
