.header-container {
  z-index: 1001;
  padding: 5px;
  color: var(--primary-color);
  background-color: var(--secondary-background-color);
  display: flex;
  flex-direction: row;
  min-height: 4vh;
  max-height: 6vh;
  width: 100%;
  position: fixed;
}

.name-container {
  margin-left: 20px;
  width: 33%;
  display: flex;
  -ms-flex-align: start;
  align-items: center;
  white-space: nowrap;
}

.social-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 33%;
  gap: 10px;
}

.nav-container {
  margin-right: 2.5%;
  width: 33%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.header-container p {
  font-size: clamp(1.5rem, 1vw, 3rem);
  font-weight: 700;
}

.nav-container p:hover {
  cursor: pointer;
  color: var(--secondary-color);
}

.name-container p:hover {
  cursor: pointer;
  color: var(--secondary-color);
}

button {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

button svg {
  color: inherit;
}

button:hover {
  color: #a9a9a9;
}

.header-link-container {
  display: flex;
  gap: 15px;
}

.nav-toggle {
  display: none;
  font-size: 32px;
  columns: #fff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header-container {
    height: 5vh;
  }

  .header-link-container {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: var(--background-color);
  }
  .header-link-container.open {
    display: flex;
    padding: 0 2rem;
    width: fit-content;
    margin-top: 27vh;
    position: absolute;
    right: 0;
  }

  .header-link-container p {
    margin: 5px;
  }

  .nav-toggle {
    display: flex;
    margin-right: 5px;
  }

  .name-container {
    margin-left: 5px;
  }
  .social-container {
    margin-left: 30px;
  }

  button {
  }
}
