.link {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 25px;
}

.link:hover {
  background-color: var(--secondary-color);
}

.container {
  display: flex;
  align-items: center;
  color: white;
}

.link-icon-container {
  width: 3.5vh;
  height: 3.5vh;
  margin: 5px;
}

.link-text-container {
  font-size: 1.1rem;
  font-weight: 700;
  margin-right: 10px;
}

@media (max-width: 768px) {
  
  .link {
    border-radius: 25px;
    width: 20vw;
    height: 7.5vw;
  }
  
  .link-icon-container {
    width: 3.5vh;
    height: 3.5vh;
    margin: 5px;
  }
  
  .link-text-container {
    font-size: 2.5vw;
    font-weight: 700;
    margin-right: 10px;
  }

}

@media (max-width: 425px) {
  
  .link {
    border-radius: 25px;
    width: 22.5vw;
    height: 10vw;
  }
  
  .link-icon-container {
    width: 2.75vh;
    height: 2.75vh;
    margin: 5px;
  }

}