.profile-container {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75vw;
  height: 27.5vw;
  background-color: var(--secondary-background-color);
  border-radius: 40px;
  padding: 2.5%;
}

.left-column {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0px;
  margin: 0px;
}

.right-column {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture-container {
  margin-top: 1.5vw;
  margin-left: 5%;
  background-color: grey;
  width: 22.5vw;
  height: 22.5vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.location-text {
  margin: 0;
  margin-left: 5%;
}

/* Name Text */
.profile-container h1 {
  margin: 0px;
  font-size: 3.8vw;
  letter-spacing: 0.05cap;
  height: 5vw;
}

/* Role / Position Text */
.profile-container h2 {
  margin: 0px;
  height: 4vw;
  font-size: 2.5vw;
  color: var(--primary-color);
}

/* Personal Description text */
.profile-container p {
  font-size: 1.25vw;
  line-height: 2vw;
  text-align: left;
}

.resume {
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 700;
}

.resume:hover {
  color: var(--secondary-color);
}

.right-column p {
  color: var(--primary-color);
  font-weight: 700;
}

/* For Phones and Smaller Screens */
@media (max-width: 768px) {
  .profile-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 85vw;
    height: 65vw;
    background-color: var(--secondary-background-color);
    border-radius: 40px;
    padding: 5%;
    padding-top: 2.5%;
  }

  .left-column {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0px;
    margin: 0px;
  }

  .right-column {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-picture-container {
    margin-top: 15vw;
    margin-left: 5%;
    background-color: grey;
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .right-column .location-text {
    font-size: 3vw;
  }

  .location-text {
    margin: 0;
    margin-left: 5%;
    white-space: nowrap;
  }

  /* Name Text */
  .profile-container h1 {
    margin: 0px;
    margin-top: 10px;
    font-size: 6vw;
    letter-spacing: 0.05cap;
    height: 7vw;
    white-space: nowrap;
  }

  /* Role / Position Text */
  .profile-container h2 {
    margin: 0px;
    font-size: 4vw;
    color: var(--primary-color);
    height: 7vw;
  }

  /* Personal Description text */
  .profile-container p {
    margin: 0px;
    font-size: 3.5vw;
    line-height: 4vw;
    text-align: left;
    margin-bottom: 2.5vw;
  }

  .resume {
    font-size: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 700;
  }

  .resume:hover {
    color: var(--secondary-color);
  }

  .right-column p {
    color: var(--primary-color);
    font-weight: 700;
  }
}
