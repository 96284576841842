.project-information-container {
  border-radius: 40px;
  padding: 2.5%;
  margin: 5%;
  height: 75vh;
  width: 60vw;
  background-color: var(--secondary-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-container {
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 60%;
  width: 80%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-arrow {
  margin: 2.5%;
  padding: 1.25vh;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  font-size: 1.5vw;
  width: 3vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-arrow:hover {
  background-color: var(--secondary-color);
}

.left {
  transform: rotateY(180deg);
}

.project-information {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.75rem;
  position: relative;
}

.project-information .caption {
  margin: 0;
  margin-top: 5px;
  text-align: center;
  font-size: 0.95rem;
}

.caption-container {
  height: 15%;
}

.project-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.project-information h2 {
  margin: 0;
  font-size: 2rem;
}

.link-container {
  display: flex;
  gap: 10px;
}

.project-information p {
  margin-top: 0.5%;
  flex: 1;
  font-size: 1.2rem;
  line-height: 110%;
}

.project-technologies {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  margin-top: auto;
}

@media (max-width: 768px) {
  .project-information-container {
    border-radius: 40px;
    padding: 7.5%;
    margin: 10%;
    height: 100%;
    width: 80vw;
    background-color: var(--secondary-background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 40%;
    width: 90%;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .image-arrow {
    margin: 1.5vw;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    font-size: 3vw;
    width: 1.5vw;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .project-information .caption {
    margin: 0;
    margin-top: 5px;
    text-align: center;
    font-size: 2.5vw;
  }

  .project-name-container {
    margin: 0;
    margin-top: 1vh;
    justify-content: center;
    align-items: center;
    width: 80vw;
    text-align: left;
    flex-wrap: wrap;
  }

  .project-information h2 {
    font-size: 6vw;
    white-space: nowrap;
    text-align: center;
  }

  .project-information p {
    margin-top: 2.5vh;
    flex: 1;
    font-size: 3.5vw;
    line-height: 110%;
  }

  .project-technologies {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5px;
    margin-top: auto;
  }
}
