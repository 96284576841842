:root {
  /* Primary and Secondary Colors */
  --primary-color: #007bff;
  --secondary-color: #0056b3;

  /* Light Mode Colors */
  --background-color-light: #ffffff;
  --text-color-light: #000000;
  --secondary-background-color-light: #f1f1f1; /* Lighter than white but darker than the background */

  /* Dark Mode Colors */
  --background-color-dark: #2b2d31;
  --text-color-dark: #ffffff;
  --secondary-background-color-dark: #232428; /* Darker than the dark background */

  /* Default Colors (Light Mode) */
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --secondary-background-color: var(--secondary-background-color-light);
}

/* Gradient Background for profile */
.background-gradient {
  background: linear-gradient(
    109.6deg,
    var(--background-color) 11.2%,
    var(--secondary-background-color) 78.9%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.2);
}
.dark-mode .background-gradient {
  background: linear-gradient(
    115deg,
    var(--background-color-dark) 15%,
    var(--secondary-background-color-dark) 85%
  );
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.35);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

.ref-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  height: 100vh;
  width: 100vw;
  text-align: center;
  height: fit-content;
  overflow-x: hidden;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.app-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1vh;
}

.section-header {
  margin: 2%;
  text-align: left;
  width: 90vw;
  font-size: 2.5vh;
  color: var(--primary-color);
}

.App-link {
  color: var(--primary-color);
}

/* Dark Mode Toggle */
.dark-mode {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --secondary-background-color: var(--secondary-background-color-dark);
}
