.technology {
  padding: 1.25vh;
  border-radius: 25px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1vw;
  font-weight: 650;
  margin-right: 5px;
}


@media (min-width: 600px) and (max-width: 768px) {
  .technology {
    padding: 3%;
    border-radius: 25px;
    background-color: var(--primary-color);
    color: white;
    font-size: 2.5vw;
  }
}

@media (max-width: 600px) {
  .technology {
    padding: 3%;
    border-radius: 25px;
    background-color: var(--primary-color);
    color: white;
    font-size: 2.5vw;
  }
}
