.social-link {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.social-link:hover {
  background-color: var(--secondary-color);
  transition: background-color 0.3s ease;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.name-container p {
  font-size: 2rem;
}

/* Tablet */
@media (max-width: 768px) {
  .social-link {
    width: 40px;
    height: 40px;
  }
  
  .social-container {
    margin-left: 8vw;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
  }

  .name-container p {
    font-size: 1.5rem;
  }
}


/* Phone Size */
@media (max-width: 425px) {


  .nav-container button {
    margin-right: -15px;
  }

  .social-link {
    width: 30px;
    height: 30px;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
  }

  .name-container p {
    font-size: 1.25rem;
  }
}

