.project-card-container {
  border: 5px solid var(--secondary-background-color);
  background-color: var(--secondary-background-color);
  width: 33vw;
  height: 33vw;
  margin-inline: 1vw;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card-container.selected {
  border-color: var(--primary-color);
  border-radius: 15px;
  z-index: 1000;
}

.project-image-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: grey;
  height: 50%;
}

.project-image {
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.project-content {
  height: 45%;
  margin: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.95vw;
  position: relative;
}

/* Project Name */
.project-content h2 {
  margin: 0;
  font-size: 1.5vw;
}

/* Project Description */
.project-content p {
  flex: 1;
  margin-top: 5px;
}

.project-technologies {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: 10px;
}


@media (min-width: 600px) and (max-width: 768px) {
  .project-card-container {
    background-color: var(--secondary-background-color);
    width: 80vw;
    height: 90vw;
    box-sizing: border-box;
    overflow: hidden; 
  }

  .project-image-container {
    background-color: grey;
    height: 50%; 
  }

  .project-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 3vw; 
    position: relative;
    overflow: hidden; 
  }

  .project-content h2 {
    margin: 0;
    font-size: 5vw;
  }

  .project-technologies {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5px;
    margin-top: auto;
  }
}

/* Phone Screens */
@media (max-width: 600px) {
  .project-card-container {
    border: 5px solid var(--secondary-background-color);
    background-color: var(--secondary-background-color);
    width: 75vw;
    height: 90vw;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .project-image-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: grey;
    height: 40%;
  }

  .project-image {
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .project-content {
    height: 55%;
    margin: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1rem;
    position: relative;
  }

  /* Project Name */
  .project-content h2 {
    margin: 0;
    font-size: 6vw;
    white-space: nowrap;
  }

  /* Project Description */
  .project-content p {
    flex: 1;
    font-size: 4vw;
    margin-top: 5px;
  }

  .project-technologies {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: auto;
    margin-bottom: 10px;
  }
}
